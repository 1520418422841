import React from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, Divider } from '@mui/material';
import { IScreenProps } from '../types';

export const Email: React.FC<IScreenProps> = ({ defaultEmailAddress, handleEmail, showSignUp }) => {
  const { t } = useLocale();
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography>{t('Authorization.subtitle', { appName: 'Charlie' })}</Typography>

      <Box sx={{ my: 3 }}>
        <FormController onSubmit={handleEmail} defaultValues={{ email: defaultEmailAddress }}>
          <Form.Email name="email" isAutoFocus i18n={{ keyPrefix: 'Authorization.Email.FormEmail' }} />
          <Form.ButtonSubmit sx={{ p: 1, width: '100% ' }} />
        </FormController>
      </Box>

      <Divider sx={{ mt: 1, mb: 1 }}>{t('Authorization.or')}</Divider>

      <Typography>
        {t('Authorization.Email.needAccount')}
        <Button variant="text" sx={{ textTransform: 'none' }} onClick={showSignUp}>
          {t('Authorization.Email.createAccount', { type: 'an' })}
        </Button>
      </Typography>
    </Box>
  );
};
