import React, { useCallback, useState } from 'react';
import { Stack, Box } from '@mui/material';
import charlieLogo from 'assets/heart.png';
import charlieLogoHover from 'assets/heartHover.png';

export const Logo: React.FC = () => {
  const [isHover, setIsHover] = useState(false);

  const handleOnMouseOver = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleOnMouseOut = useCallback(() => {
    setIsHover(false);
  }, []);

  return (
    <Stack direction="row" justifyContent="left" alignItems="center">
      <Box
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        sx={{
          padding: '2px 5px',
          fontSize: 24,
          color: '#5584ac',
          letterSpacing: -1,
          transform: 'scaleY(90%)'
        }}
      >
        <img
          src={isHover ? charlieLogoHover : charlieLogo}
          alt="Charlie logo"
          width={35}
          style={{ position: 'relative', top: 5, left: -5 }}
        />
        <span style={{ fontWeight: 700, fontFamily: 'Iosevka Web', color: '#220b53' }}>Charlie</span>
      </Box>
    </Stack>
  );
};
