import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useResourceList } from '@koopajs/react';

/*
const CURRENCIES: { [k: string]: string } = {
  CAD: '🇨🇦',
  USD: '🇺🇸',
  GBP: '🇬🇧',
  EUR: '💶'
};
*/

export const FinancesAssets: React.FC = () => {
  const { resources } = useResourceList({ path: '/bank-assets' });
  const [columnDefs] = useState([
    {
      field: 'type',
      width: 200
    },
    //{ field: 'name'},
    {
      field: 'symbol',
      width: 200,
      flex: 1
    },
    {
      field: 'country',
      width: 125
    },
    /*{
      headerName: '',
      field: 'currency',
      width: 75,
      valueFormatter: (params:any) => CURRENCIES[params.data.currency as string] || params.data.currency
    },*/
    {
      field: 'value',
      type: 'numericColumn',
      sortable: true,
      valueFormatter: (params: { data: { value: number; currency: string } }) =>
        new Intl.NumberFormat('en-CA', {
          style: 'currency',
          currency: params.data.currency,
          maximumFractionDigits: 0
        }).format(params.data.value)
    },
    {
      field: 'valueCAD',
      type: 'numericColumn',
      sortable: true,
      //sort: 'desc',
      valueFormatter: (params: { data: { valueCAD: number } }) =>
        new Intl.NumberFormat('en-CA', {
          style: 'currency',
          currency: 'CAD',
          maximumFractionDigits: 0
        }).format(params.data.valueCAD)
    },
    {
      field: 'fxToCAD',
      type: 'numericColumn',
      sortable: true,
      width: 150,
      valueFormatter: (params: { data: { fxToCAD: number } }) => params.data.fxToCAD.toFixed(3)
    }
  ]);
  if (!resources) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Finances/Assets &mdash; Charlee</title>
      </Helmet>
      <Box className="ag-theme-balham" style={{ height: 400, width: '100%' }}>
        <AgGridReact rowData={resources} columnDefs={columnDefs} />
      </Box>
    </>
  );
};
