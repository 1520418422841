import { createTheme, Theme } from '@mui/material/styles';
import '../../theme.css';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#220b53'
    },
    secondary: {
      main: '#21c0f2'
    },
    info: {
      main: '#ff6a4d'
    },
    success: {
      main: '#C7E590'
    },
    error: {
      main: '#C5221F'
    }
  },
  typography: {
    fontFamily: 'Circular Std'
  }
});

//#ff6a4d orange
// dark red #7d1b3b
