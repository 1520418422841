import React from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, Alert, InputAdornment, TextField } from '@mui/material';
import { IScreenProps } from '../types';

export const Passwordless: React.FC<IScreenProps> = ({
  showSignIn,
  handlePasswordless,
  authSession,
  usePassword
}) => {
  const { t } = useLocale();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography>{t('Authorization.subtitle', { appName: 'Charlie' })}</Typography>

      <Box sx={{ my: 3 }}>
        <FormController onSubmit={handlePasswordless}>
          <TextField
            label={t('Authorization.Email.FormEmail.label')}
            defaultValue={authSession?.email}
            fullWidth
            disabled
            sx={{ mt: 2, mb: 1 }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button size="small" onClick={showSignIn}>
                    {t('Authorization.labelEdit')}
                  </Button>
                </InputAdornment>
              )
            }}
          />

          <Form.TextField
            name="code"
            i18n={{ keyPrefix: 'Authorization.Passwordless.FormTextField' }}
            isAutoFocus
            sx={{ mt: 2, mb: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button size="small" onClick={usePassword}>
                    {t('Authorization.Passwordless.labelUsePassword')}
                  </Button>
                </InputAdornment>
              )
            }}
          />

          <Form.ButtonSubmit sx={{ p: 1, width: '100% ' }} />
        </FormController>

        <Alert severity="success" sx={{ textAlign: 'left', my: 2 }}>
          {t('Authorization.Passwordless.alertSuccess', { email: authSession?.email })}.
        </Alert>
      </Box>
    </Box>
  );
};
