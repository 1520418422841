import React from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, Alert } from '@mui/material';
import { IScreenProps } from '../types';

export const SignUpConfirmation: React.FC<IScreenProps> = ({
  authSession,
  handleConfirmSignUp,
  handleResendCode,
  isProcessing
}) => {
  const { t } = useLocale();
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography> {t('Authorization.Email.createAccount', { type: 'your' })}</Typography>

      <Alert severity="success" sx={{ textAlign: 'left', my: 2 }}>
        {t('Authorization.SignUpConfirmation.alertSuccess', { email: authSession?.email })}
      </Alert>

      <FormController onSubmit={handleConfirmSignUp}>
        <Form.TextField i18n={{ keyPrefix: 'Authorization.ForgotPassword.FormTextField' }} name="code" />
        <Form.ButtonSubmit sx={{ p: 1, width: '100% ' }} />
      </FormController>

      <Box sx={{ textAlign: 'left', mt: 3, display: 'flex', alignItems: 'center' }}>
        <Typography>{t('Authorization.SignUpConfirmation.didNotReceive')}</Typography>
        <Button
          onClick={handleResendCode}
          variant="text"
          sx={{ textTransform: 'none' }}
          disabled={isProcessing}
        >
          {t('Authorization.SignUpConfirmation.labelResendCode')}
        </Button>
      </Box>
    </Box>
  );
};
