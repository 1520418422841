import React from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { useUserShow } from '@koopajs/react';
import { DateTime } from 'luxon';
import { Redirect, useParams } from 'react-router-dom';
import { DailySchedule } from './Schedule';
import { Region } from './Region';

export const Daily: React.FC = () => {
  const { user } = useUserShow();
  const { date: requestedDate } = useParams<{ date?: string }>();
  const today = DateTime.now().toFormat('yyyy-MM-dd');

  if (!requestedDate) {
    return <Redirect to={`/daily/${today}`} />;
  }

  return (
    <Box>
      <Typography variant="h1" sx={{ m: 3, fontSize: { xs: '3rem', md: '6rem' } }}>
        Hello {user?.firstname as string}.
        {/* No matter what anybody tells you, words and ideas can change the world. */}
      </Typography>

      <Stack>
        <DailySchedule date={requestedDate} />
        <Region />
      </Stack>
    </Box>
  );
};
