import React, { useState, useCallback } from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, TextField, InputAdornment, Divider, IconButton } from '@mui/material';
import { IScreenProps } from '../types';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const Password: React.FC<IScreenProps> = ({
  authSession,
  showSignIn,
  usePasswordless,
  showForgotPassword,
  handlePassword
}) => {
  const { t } = useLocale();
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleShowPassword = useCallback(() => {
    setPasswordVisible((prevState) => !prevState);
  }, [isPasswordVisible]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography>{t('Authorization.subtitle', { appName: 'Charlie' })}</Typography>

      <Box sx={{ my: 3 }}>
        <FormController onSubmit={handlePassword}>
          <TextField
            label={t('Authorization.Email.FormEmail.label')}
            defaultValue={authSession?.email}
            fullWidth
            disabled
            sx={{ mt: 2, mb: 1 }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button size="small" onClick={showSignIn}>
                    {t('Authorization.labelEdit')}
                  </Button>
                </InputAdornment>
              )
            }}
          />

          <Form.TextField
            name="password"
            type={isPasswordVisible ? 'text' : 'password'}
            isAutoFocus
            i18n={{ keyPrefix: 'Authorization.Password.FormTextField' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    {!isPasswordVisible && <VisibilityOffIcon />}
                    {isPasswordVisible && <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Form.ButtonSubmit sx={{ p: 1, width: '100% ' }} />
        </FormController>

        <Divider sx={{ mt: 5, mb: 1, fontSize: 14 }}>{t('Authorization.Password.needHelp')}</Divider>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, mt: 1 }}>
          <Button size="small" variant="text" sx={{ textTransform: 'none' }} onClick={showForgotPassword}>
            {t('Authorization.Password.labelResetPassword')}
          </Button>
          <Button size="small" onClick={usePasswordless} variant="text" sx={{ textTransform: 'none' }}>
            {t('Authorization.Password.labelPasswordless')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
