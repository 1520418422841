import React, { useEffect, useState } from 'react';
import { Avatar, AvatarGroup, Card, CardHeader, CardActionArea } from '@mui/material';
import { DateTime } from 'luxon';

type ClockStatus = 'planned' | 'starting-soon' | 'in-progress' | 'finished';

export interface IEventProps {
  meeting: {
    name: string;
    startAt: string;
    endAt: string;
    attendees?: {
      email: string;
    }[];
  };
  backgroundColor?: string;
}

export const DailyScheduleEvent: React.FC<IEventProps> = (props) => {
  const { meeting, backgroundColor } = props;

  const [clock, setClock] = useState<{
    now: DateTime;
    duration: number;
    status: ClockStatus;
  }>();

  const start = DateTime.fromISO(meeting.startAt);
  const end = DateTime.fromISO(meeting.endAt);

  useEffect(() => {
    const updateTime = (): void => {
      const now = DateTime.now();
      const startsInMs = start.diff(now).milliseconds;
      const endsInMs = end.diff(now).milliseconds;
      const duration = Math.round(end.diff(start, 'minutes').minutes);
      let status: ClockStatus;

      if (startsInMs > 0 && startsInMs < 90 * 60 * 1000) {
        status = 'starting-soon';
      } else if (startsInMs < 0 && endsInMs > 0) {
        status = 'in-progress';
      } else if (startsInMs < 0 && endsInMs < 0) {
        status = 'finished';
      } else {
        status = 'planned';
      }

      setClock({ now, duration, status });
    };
    updateTime();
    const interval = setInterval(updateTime, 1_000);

    return () => clearInterval(interval);
  }, [meeting.startAt, meeting.endAt]);

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 1,
        backgroundColor,
        opacity: clock?.status === 'finished' ? 0.5 : 1,
        boxShadow: clock?.status === 'in-progress' ? `0px 0px 30px 0px ${backgroundColor};` : ''
      }}
    >
      <CardActionArea>
        <CardHeader
          avatar={
            <AvatarGroup spacing="small" max={3}>
              {meeting.attendees?.map((attendee: { email: string }) => (
                <Avatar key={attendee.email} />
              ))}
            </AvatarGroup>
          }
          title={`${meeting.name} - ${clock?.status}`}
          subheader={
            clock && (
              <>
                {(clock.status === 'planned' || clock.status === 'finished') && (
                  <>
                    {start.toFormat('HH:mm')} &mdash; {clock.duration} min
                  </>
                )}
                {clock.status === 'starting-soon' && (
                  <>
                    Starts {start.toRelative()} &mdash; {clock.duration} min
                  </>
                )}
                {clock.status === 'in-progress' && (
                  <>
                    Started {start.toRelative()} &mdash; Ends {end.toRelative()}
                  </>
                )}
              </>
            )
          }
        />
      </CardActionArea>
    </Card>
  );
};
