import React from 'react';
import { App as AppCore } from '@koopajs/mui';
import { useComponentVisibility } from '@koopajs/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialogs } from 'components/Dialogs';
import { Onboarding } from './Onboarding';
import { UserProfile } from './UserProfile';
import { Router } from 'pages/Router';
import { Loading } from './Loading';
import { Header } from './Header';
import { theme } from './theme';
import Drawer from '@mui/material/Drawer';
import { AWSCognitoAuthentication } from './AWSCognitoAuthentication';

const AppInternal: React.FC = () => {
  const userMenu = useComponentVisibility('userMenu');
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Router />
      <Dialogs />
      <Drawer
        anchor={isLargeScreen ? 'right' : 'bottom'}
        open={userMenu.isVisible}
        onClose={userMenu.setHidden}
      >
        <UserProfile />
      </Drawer>
    </>
  );
};

export const App: React.FC = () => (
  <AppCore.Provider
    muiTheme={theme}
    componentOnboarding={Onboarding}
    componentAuthentication={AWSCognitoAuthentication}
    componentLoading={Loading}
    header={Header}
  >
    <AppInternal />
  </AppCore.Provider>
);
