import { useUserShow, useUserUpdate, FormController } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { ErrorMessage, Form } from '@koopajs/mui';
import { useCallback } from 'react';
import { Button, Typography, Box } from '@mui/material';

export interface IContentNameProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentName: React.FC<IContentNameProps> = (props: IContentNameProps) => {
  const { moveNext, movePrevious } = props;

  const { user, isProcessing: isProcessingUserShow, errorMessage: errorMessageUserShow } = useUserShow();
  const {
    updateUser,
    isProcessing: isProcessingUserUpdate,
    errorMessage: errorMessageUserUpdate
  } = useUserUpdate();

  const onSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const isSuccessful = await updateUser({ ...formData });

    if (isSuccessful) {
      moveNext();
      TrackEvent('onboarding-name');
    }

    return isSuccessful;
  }, []);

  return (
    <Box>
      <Typography>This helps personalized your product experience.</Typography>

      <ErrorMessage error={errorMessageUserShow} />
      <ErrorMessage error={errorMessageUserUpdate} />

      <Box>
        <Box>
          <Box className={'style.inputWrapper'} style={{ marginTop: 10, marginBottom: 10 }}>
            <FormController
              onSubmit={onSubmit}
              defaultValues={{ firstname: user?.firstname || '', lastname: user?.lastname || '' }}
            >
              <Form.TextField
                isAutoFocus
                name="firstname"
                label="First name"
                errorText="Must be between 2 and 50 characters"
                validationRules={{ minLength: 2, maxLength: 50 }}
              />
              <Form.TextField
                name="lastname"
                label="Last name"
                errorText="Must be between 2 and 50 characters"
                validationRules={{ minLength: 2, maxLength: 50 }}
              />
              <Button onClick={movePrevious} disabled={isProcessingUserShow || isProcessingUserUpdate}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isProcessingUserShow || isProcessingUserUpdate}
                type="submit"
              >
                Next
              </Button>
            </FormController>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
