import { Sentry, Amplify } from '@koopajs/plugins';
import { StartReactApp } from '@koopajs/react';

import { App } from './components/Core/app';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import enTranslations from './locales/en/default.json';
import frTranslations from './locales/fr-ca/default.json';

import enKoopaCore from '@koopajs/mui/dist/locales/en/koopaCore.json';
import frKoopaCore from '@koopajs/mui/dist/locales/fr/koopaCore.json';

StartReactApp(<App />, {
  locales: {
    additionalLocaleSupported: ['en', 'fr-CA'],
    translations: {
      en: { translation: enTranslations, koopaCore: enKoopaCore },
      'fr-CA': { translation: frTranslations, koopaCore: frKoopaCore }
    }
  },
  plugins: [new Amplify(), new Sentry()]
});
