import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Omnibar from 'omnibar';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserShow, useWorkspaceShow, useComponentVisibility } from '@koopajs/react';
import { TopBarUser } from '@koopajs/mui/dist/components/App/TopBarUser';
import { Logo } from '../Logo';
import { Toolbar, Box } from '@mui/material';

const DeepLinkExtension = (query: string): { title: string; deepLink: string }[] => {
  return [
    { title: 'Finances', deepLink: '/finances' },
    { title: 'Finances / Portfolio', deepLink: '/finances/portfolios' },
    { title: 'Finances / Assets', deepLink: '/finances/assets' },
    { title: 'Finances / Budgets', deepLink: '/finances/budgets' }
  ];
};

export const Header: React.FC = () => {
  const history = useHistory();
  const { user } = useUserShow();
  const { workspace } = useWorkspaceShow();
  const userMenu = useComponentVisibility('userMenu');

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const usernames: [string] | [string, string] = [`${user?.firstname} ${user?.lastname}`];
  if (workspace?.name) {
    usernames.push(workspace.name);
  }

  const onSelect = useCallback((item: unknown): void => {
    const itemTypes = item as { title: string; deepLink?: string };
    if (itemTypes.deepLink) {
      return history.push(itemTypes.deepLink);
    }

    // alert(JSON.stringify({ itemTypes }));
  }, []);

  return (
    <Toolbar sx={{ height: 64 }}>
      <Box sx={{ flex: 'auto' }}>
        <Link to="/" style={{ textDecoration: 'none', marginTop: 12 }}>
          <Logo />
        </Link>
      </Box>

      <Box sx={{ maxWidth: 600, flex: 'auto' }}>
        <Omnibar autoFocus placeholder="Enter command" extensions={[DeepLinkExtension]} onAction={onSelect} />
      </Box>

      {/* <div /> */}

      <Box sx={{ flex: 'auto', textAlign: 'right' }}>
        <TopBarUser
          usernames={isLargeScreen ? usernames : undefined}
          avatarSrc={user?.profilePicture}
          workspaceAvatar={workspace?.icon}
          setMenuUserVisible={userMenu.setVisible}
        />
      </Box>
    </Toolbar>
  );
};
