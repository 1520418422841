import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Account } from './Account';
import { NotFound } from './NotFound';
import { Settings } from './Settings';
import { Invitation } from './Invitation';
import { Finances } from './Finances';
import { Daily } from './daily';

export const Router: React.FC = () => (
  <Switch>
    <Route path="/account" component={Account} />
    <Route path="/settings" component={Settings} />
    <Route path="/finances" component={Finances} />
    <Route path="/daily/:date?" component={Daily} />
    <Route path="/invitation/:id" exact component={Invitation} />
    <Redirect path="/" exact to="/daily" />
    <Route path="*" component={NotFound} />
  </Switch>
);
