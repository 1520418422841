import React from 'react';
import { Box, Card, CardHeader } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useResourceList } from '@koopajs/react';

export const FinancesBudgets: React.FC = () => {
  const { resources } = useResourceList({ path: '/budgets' });

  if (!resources) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Finances/Budgets &mdash; Charlee</title>
      </Helmet>
      <Box>
        {resources.map((r) => (
          <Card key={r.id}>
            <CardHeader
              title={`${r.name}`}
              subheader={`${r.frequency} budget of ${new Intl.NumberFormat('en-CA', {
                style: 'currency',
                currency: 'CAD'
              }).format(r.targetValue as number)}`}
            />
          </Card>
        ))}
      </Box>
    </>
  );
};
