import { Auth } from 'aws-amplify';
import { Api } from '@koopajs/app';
import { IAuthSession, Next } from './types';

export interface IUseLoginResponse {
  initiate: (email: string, preferredFlow?: string) => Promise<IAuthSession>;
  password: {
    check: (session: IAuthSession, password: string) => Promise<IAuthSession>;
    setup: (session: IAuthSession, newPassword: string) => Promise<IAuthSession>;
    change: (session: IAuthSession, oldPassword: string, newPassword: string) => Promise<string>;
    forgot: (session: IAuthSession) => Promise<IAuthSession>;
    forgotChange: (session: IAuthSession, code: string, newPassword: string) => Promise<string>;
  };

  mfa: {
    check: (session: IAuthSession, mfaCode: string) => Promise<IAuthSession>;
    setup: (session: IAuthSession) => Promise<string>;
    initialVerify: (session: IAuthSession, challengeAnswer: string) => Promise<void>;
  };

  passwordless: {
    check: (session: IAuthSession, passwordlessCode: string) => Promise<IAuthSession>;
  };

  signUp: {
    setup: (username: string, password: string) => Promise<IAuthSession>;
    confirm: (session: IAuthSession, code: string) => Promise<unknown>;
    resendCode: (session: IAuthSession) => Promise<unknown>;
  };

  //signOut: () => void;
}

export const useLogin = (): IUseLoginResponse => {
  const wrapSessionResponse = (
    cognitoUser: { challengeName?: string; codeDeliveryDetails?: { DeliveryMedium?: string } },
    oldSession: IAuthSession
  ): IAuthSession => {
    const { email } = oldSession;

    let next: Next = 'password';
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      next = 'new-password';
    } else if (cognitoUser.challengeName === 'MFA_SETUP') {
      next = 'new-mfa';
    } else if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
      next = 'mfa';
    } else if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
      next = 'passwordless';
    } else if (cognitoUser.codeDeliveryDetails?.DeliveryMedium === 'EMAIL') {
      next = 'signup-confirmation';
    }

    return { next, email, cognitoUser };
  };

  const initiate = async (email: string, preferredFlow?: string): Promise<IAuthSession> => {
    const response = await Api.client.post<IAuthSession>('/idp', { email, preferredFlow });
    const cognitoUser = await Auth.signIn(email);

    return {
      ...response.data,
      cognitoUser
    };
  };

  const password = {
    check: async (session: IAuthSession, password: string): Promise<IAuthSession> => {
      const response = await Auth.signIn(session.email, password);

      return wrapSessionResponse(response, session);
    },

    setup: async (session: IAuthSession, newPassword: string): Promise<IAuthSession> => {
      const response = await Auth.completeNewPassword(session.cognitoUser, newPassword);
      return wrapSessionResponse(response, session);
    },

    change: async (session: IAuthSession, oldPassword: string, newPassword: string): Promise<string> => {
      return Auth.changePassword(session.cognitoUser, oldPassword, newPassword);
    },

    forgot: async (session: IAuthSession): Promise<IAuthSession> => {
      const response = await Auth.forgotPassword(session.email);
      return wrapSessionResponse(response, session);
    },
    forgotChange: async (session: IAuthSession, code: string, newPassword: string): Promise<string> => {
      return Auth.forgotPasswordSubmit(session.email, code, newPassword);
    }
  };

  const mfa = {
    check: async (session: IAuthSession, code: string): Promise<IAuthSession> => {
      const response = await Auth.confirmSignIn(session.cognitoUser, code, 'SOFTWARE_TOKEN_MFA');
      return wrapSessionResponse(response, session);
    },

    setup: async (session: IAuthSession): Promise<string> => {
      return Auth.setupTOTP(session.cognitoUser);
    },

    initialVerify: async (session: IAuthSession, initialCode: string): Promise<void> => {
      await Auth.verifyTotpToken(session.cognitoUser, initialCode);
      await Auth.setPreferredMFA(session.cognitoUser, 'TOTP');
    }
  };

  const passwordless = {
    check: async (session: IAuthSession, code: string): Promise<IAuthSession> => {
      const response = await Auth.sendCustomChallengeAnswer(session.cognitoUser, code);

      await Auth.currentSession().catch((e) => {
        throw new Error('invalid code for passwordless login');
      });

      return wrapSessionResponse(response, session);
    }
  };

  //const signOut = async (): Promise<void> => await Auth.signOut();

  const signUp = {
    setup: async (username: string, password: string): Promise<IAuthSession> => {
      const response = await Auth.signUp({ username, password, autoSignIn: { enabled: true } });
      return wrapSessionResponse(response, { email: username });
    },
    confirm: async (session: IAuthSession, code: string): Promise<unknown> => {
      return await Auth.confirmSignUp(session.email, code);
    },
    resendCode: async (session: IAuthSession): Promise<unknown> => {
      return await Auth.resendSignUp(session.email);
    }
  };

  return {
    initiate,
    password,
    passwordless,
    mfa,
    //signOut,
    signUp
  };
};
