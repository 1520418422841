import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useHistory } from 'react-router-dom';
import { useResourceShow } from '@koopajs/react';
import { DailyScheduleEvent } from './Event';

export const DailySchedule: React.FC<{ date: string }> = ({ date }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const todayDateTime = DateTime.now();
  const requestDateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');
  const isToday = Boolean(todayDateTime.toFormat('yyyy-MM-dd') === requestDateTime.toFormat('yyyy-MM-dd'));
  const isTomorrow = Boolean(
    todayDateTime.toFormat('yyyy-MM-dd') === requestDateTime.minus({ day: 1 }).toFormat('yyyy-MM-dd')
  );

  const moveToDateTime = useCallback((newDateTime: DateTime) => {
    return () => history.push(`/daily/${newDateTime.toFormat('yyyy-MM-dd')}`);
  }, []);

  const { isProcessing, resource } = useResourceShow<{
    workspaceId: string;
    id: string;
    events: {
      name: string;
      colors?: {
        background: string;
        foreground: string;
      };
      meetings: {
        id: string;
        name: string;
        startAt: string;
        endAt: string;
        attendees?: {
          email: string;
        }[];
      }[];
    }[];
  }>({ path: '/daily', id: date });

  return (
    <Box sx={{ m: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          {isToday && 'Today'}
          {isTomorrow && 'Tomorrow'}
          {!isToday && !isTomorrow && requestDateTime.toFormat(isMobile ? 'ccc, d LLLL' : 'cccc, d LLLL')}
        </Typography>
        <Box>
          <Tooltip title="Previous day">
            <IconButton disabled={isProcessing} onClick={moveToDateTime(requestDateTime.minus({ day: 1 }))}>
              <ChevronLeftRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Today">
            <IconButton disabled={isProcessing} onClick={moveToDateTime(todayDateTime)}>
              <CalendarMonthIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next day">
            <IconButton disabled={isProcessing} onClick={moveToDateTime(requestDateTime.plus({ day: 1 }))}>
              <ChevronRightRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      <Stack spacing={4} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
        {resource?.events?.map((cat) => (
          <Stack key={cat.name} sx={{ width: { xs: '100%', md: `${100 / resource.events.length}%` } }}>
            <Typography>{cat.name}</Typography>

            <Box>
              {cat.meetings
                .sort((a, b) => (a.startAt > b.startAt ? 1 : -1))
                .map((meeting) => (
                  <DailyScheduleEvent
                    key={meeting.id}
                    meeting={meeting}
                    backgroundColor={cat.colors?.background}
                  />
                ))}
            </Box>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
