import React, { useCallback, useState } from 'react';
import {
  Typography,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Paper,
  InputAdornment,
  IconButton
} from '@mui/material';
import { PASSWORD_VALIDATION_FUNCTIONS } from '../utils';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from '@koopajs/mui';
import Popper from '@mui/material/Popper';
import { useLocale } from '@koopajs/react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface IPasswordValidationProps {
  errors: string[];
  passwordsMatch: boolean;
}

export const PasswordValidation: React.FC<IPasswordValidationProps> = (props) => {
  const { errors, passwordsMatch } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  );
  const handleBlur = useCallback(() => setAnchorEl(anchorEl), []);
  const { t } = useLocale();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isRepeatedPasswordVisible, setRepeatedPasswordVisible] = useState(false);

  const handleShowPassword = useCallback(() => {
    setPasswordVisible((prevState) => !prevState);
  }, [isPasswordVisible]);

  const handleShowRepeatedPassword = useCallback(() => {
    setRepeatedPasswordVisible((prevState) => !prevState);
  }, [isRepeatedPasswordVisible]);

  return (
    <>
      <Form.TextField
        i18n={{ keyPrefix: 'Authorization.PasswordValidation.FormTextFieldPassword' }}
        name="password"
        type={isPasswordVisible ? 'text' : 'password'}
        validationRules={{
          validate: PASSWORD_VALIDATION_FUNCTIONS,
          required: true
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword}>
                {!isPasswordVisible && <VisibilityOffIcon />}
                {isPasswordVisible && <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Form.TextField
        i18n={{ keyPrefix: 'Authorization.PasswordValidation.FormTextFieldRepeatedPassword' }}
        name="repeatedPassword"
        type={isRepeatedPasswordVisible ? 'text' : 'password'}
        validationRules={{
          validate: { isEqual: (v) => passwordsMatch },
          required: true,
          deps: 'repeatedPassword'
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowRepeatedPassword}>
                {!isRepeatedPasswordVisible && <VisibilityOffIcon />}
                {isRepeatedPasswordVisible && <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Popper
        id="poperPasswordValidationRules"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ zIndex: 10 }}
        placement="bottom-start"
      >
        <Paper sx={{ p: 2, width: '100%' }} elevation={8}>
          {errors.length > 0 ? (
            <>
              <Typography sx={{ fontSize: '14px' }}>
                {t('Authorization.PasswordValidation.passwordReq')}
              </Typography>

              <List dense>
                {Object.keys(PASSWORD_VALIDATION_FUNCTIONS).map((key) => (
                  <ListItem key={key} sx={{ opacity: errors.includes(key) ? 1 : 0.5 }} disableGutters>
                    <ListItemIcon>
                      {errors.includes(key) ? (
                        <CloseIcon fontSize="small" sx={{ color: 'red', mr: 0.5 }} />
                      ) : (
                        <DoneIcon fontSize="small" sx={{ color: 'green', mr: 0.5 }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontSize: '10px' }}>{key}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <Typography sx={{ fontSize: '14px' }}>
              🔒 {t('Authorization.PasswordValidation.passwordSuccess')}
            </Typography>
          )}
        </Paper>
      </Popper>
    </>
  );
};
