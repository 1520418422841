import React, { useCallback, useState } from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, Divider } from '@mui/material';
import { validatePassword, PASSWORD_VALIDATION_FUNCTIONS } from '../utils';
import { PasswordValidation } from './PasswordValidation';
import { IScreenProps } from '../types';

export const SignUp: React.FC<IScreenProps> = (props) => {
  const { defaultEmailAddress, handleSignUp, showSignIn } = props;
  const [errors, setErrors] = useState<string[]>(Object.keys(PASSWORD_VALIDATION_FUNCTIONS));
  const [isPasswordEqual, setPasswordEqual] = useState(false);

  const { t } = useLocale();

  const watchFields = useCallback(
    (value: { password?: string; repeatedPassword?: string; code?: string }) => {
      const check = validatePassword(value.password);
      const isEqual = value.password === value.repeatedPassword;
      setErrors(check);
      setPasswordEqual(isEqual);
    },
    []
  );
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography> {t('Authorization.Email.createAccount', { type: 'your' })}</Typography>

      <FormController
        onSubmit={handleSignUp}
        watch={watchFields}
        defaultValues={{ email: defaultEmailAddress }}
      >
        <Form.Email name="email" isAutoFocus i18n={{ keyPrefix: 'Authorization.Email.FormEmail' }} />
        <PasswordValidation errors={errors} passwordsMatch={isPasswordEqual} />
        <Form.ButtonSubmit sx={{ mt: 1, p: 1, width: '100% ' }} />
      </FormController>

      <Divider sx={{ mt: 1, mb: 1 }}> or </Divider>

      <Typography>
        {t('Authorization.SignUp.alreadyAccount')}
        <Button variant="text" sx={{ textTransform: 'none' }} onClick={showSignIn}>
          {t('Authorization.SignUp.labelLogin')}
        </Button>
      </Typography>
    </Box>
  );
};
