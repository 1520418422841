export const VALIDATION_REQUIREMENTS: { [k: string]: string }[] = [
  { id: 'lowerCase', label: 'Lowercase letter' },
  { id: 'upperCase', label: 'Uppercase letter' },
  { id: 'number', label: 'Number' },
  { id: 'specialChar', label: 'Special character (e.g.: @$!%*?&)' },
  { id: 'length', label: 'At least 8 characters long' }
];

export const PASSWORD_VALIDATION_FUNCTIONS: { [k: string]: (v: string) => boolean } = {
  lowerCase: (v) => Boolean(v.match(/[a-z]/)),
  upperCase: (v) => Boolean(v.match(/[A-Z]/)),
  number: (v) => Boolean(v.match(/[0-9]/)),
  specialChar: (v) => Boolean(v.match(/[@$!%*?&]/)),
  length: (v) => Boolean(v && v.length > 7)
};

//check color of the font
export const getColor = (fontValue: string, errors: string[]): string => {
  if (errors && errors.length) {
    if (errors.includes(fontValue)) {
      return 'red';
    }
  }
  return 'green';
};

export const validatePassword = (val: string = ''): string[] => {
  const errorArray: string[] = [];

  for (const key of Object.keys(PASSWORD_VALIDATION_FUNCTIONS)) {
    if (!PASSWORD_VALIDATION_FUNCTIONS[key](val)) {
      errorArray.push(key);
    }
  }

  return errorArray;
};
