import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Avatar, AvatarGroup, Divider, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const CurrentTime: React.FC<{ timezone: string }> = ({ timezone }) => {
  const [time, setTime] = useState('00:00am');
  const [icon, setIcon] = useState('-');
  useEffect(() => {
    const updateTime = (): void => {
      const dt = DateTime.now().setZone(timezone);

      setTime(dt.toFormat('HH:mm'));
      if (dt.hour >= 17) {
        setIcon('🌒');
      } else if (dt.hour >= 13) {
        setIcon('☀️');
      } else if (dt.hour >= 12) {
        setIcon('🍽️');
      } else if (dt.hour >= 9) {
        setIcon('☀️');
      } else if (dt.hour >= 8) {
        setIcon('☕');
      } else {
        setIcon('🌔');
      }
    };
    updateTime();
    const interval = setInterval(updateTime, 5_000);

    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <Typography variant="h5">
      <span style={{ fontFamily: 'Iosevka Web' }}>{time}</span>
      <span style={{ fontSize: 14, position: 'relative', top: -4, left: 4 }}>{icon}</span>
    </Typography>
  );
};

export const Region: React.FC = () => {
  const [regions] = useState([
    {
      name: 'San Francisco',
      timezone: 'America/Los_Angeles'
    },
    {
      name: 'Chile',
      timezone: 'America/Santiago'
    },
    {
      name: 'Montreal',
      timezone: 'America/Toronto'
    },
    {
      name: 'Oxford',
      timezone: 'Europe/London'
    }
  ]);

  return (
    <Box sx={{ m: 3 }}>
      <Typography variant="h6">Team&lsquo;s regions</Typography>
      <Stack spacing={4} direction="row" divider={<Divider orientation="vertical" flexItem />}>
        {regions.map((region) => (
          <Stack key={region.name} sx={{ width: 150 }}>
            <CurrentTime timezone={region.timezone} />
            <Typography>{region.name}</Typography>

            <AvatarGroup>
              <Avatar alt="Rox" />
              <Avatar alt="Raff" />
            </AvatarGroup>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
