import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IResource } from '@koopajs/app';

import { Card, Box, CardHeader, CardActions, Badge, Typography, Avatar, Button, Grid } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { Api } from '@koopajs/app';
import { useResourceList } from '@koopajs/react';
import { Date } from '@koopajs/mui';
interface IProvider extends IResource {
  label: string;
  key: string;
  icon: string;
}

interface IConnection extends IResource {
  label: string;
  status: string;
  icon: string;
  $createdAt: string;
  $updatedAt: string;
}

export const SettingsIntegrations: React.FC = () => {
  const [providers, setProviders] = useState<IProvider[]>([]);
  useEffect(() => {
    const core = async (): Promise<void> => {
      const { data } = await Api.client.get<{ providers: IProvider[] }>('/providers');
      setProviders(data.providers);
    };

    core().catch(console.log);
  }, []);

  const { resources: connections } = useResourceList<IConnection>({ path: '/connections' });

  const handleAddClick = (providerKey: string): React.MouseEventHandler => {
    return async () => {
      const {
        data: { redirect }
      } = await Api.client.post<{ redirect: string }>(`/providers/${providerKey}/authorize`);
      window.location.href = redirect;
    };
  };

  return (
    <>
      <Helmet>
        <title>Settings - Loopback</title>
      </Helmet>

      <Box sx={{ my: 2, py: 2 }}>
        <Typography variant="h6">Providers</Typography>
        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 12 }}>
          {providers
            .sort((item1, item2) => (item1.label > item2.label ? 1 : -1))
            .map((item) => (
              <Grid key={item.key} item xs={1} md={2} lg={4}>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt={item.key} variant="rounded" src={item.key} />}
                    titleTypographyProps={{ variant: 'h6' }}
                    title={item.label}
                    subheader={item.label}
                  />
                  <CardActions sx={{ pl: 8, pt: 0 }}>
                    <Button size="small" onClick={handleAddClick(item.key)}>
                      Add
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      <Box sx={{ my: 2, py: 2 }}>
        <Typography variant="h6">Connections</Typography>
        <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 12 }}>
          {connections.map((item) => (
            <Grid key={item.id} item xs={1} md={2} lg={4}>
              <Card>
                <CardHeader
                  avatar={
                    <Badge color="success" variant="dot">
                      <Avatar variant="rounded">
                        <GoogleIcon />
                      </Avatar>
                    </Badge>
                  }
                  title={`Google (${item.label})`}
                  subheader={
                    item.$createdAt !== item.$updatedAt ? (
                      <>
                        Updated <Date isoDate={item.$updatedAt} />
                      </>
                    ) : (
                      <>
                        Created <Date isoDate={item.$createdAt} />
                      </>
                    )
                  }
                />
                <CardActions sx={{ pl: 8, pt: 0 }}>
                  <Button size="small">Refresh</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
