import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Card } from '@mui/material';
import { ErrorMessage } from '@koopajs/mui';
import { Stack } from '@mui/system';
import { Logo } from '../Logo';
import { Hub } from 'aws-amplify';
import * as Screens from './screens';
import { useLogin } from './useLogin';
import { IScreenProps, IAuthSession } from './types';
import { useLocale } from '@koopajs/react';
import { Select, SelectChangeEvent, MenuItem, FormControl } from '@mui/material';

const LANGUAGES: { [k: string]: string } = { 'fr-CA': 'Français (CA)', 'en-US': 'English (US)' };

export const AWSCognitoAuthentication: React.FC = () => {
  const [authSession, setAuthSession] = useState<IAuthSession>({ email: '' });
  const [defaultEmailAddress, setDefaultEmailAddress] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const login = useLogin();

  const { locale, availableLocales, setLocale } = useLocale();

  const handleSetLocale = useCallback(async (event: SelectChangeEvent) => {
    await setLocale(event.target.value as string, { skipUpdateUser: true });
  }, []);

  const showSignUp = useCallback(() => {
    setAuthSession({ email: '', next: 'signup' });
  }, []);

  const showSignIn = useCallback(() => {
    setAuthSession({ email: defaultEmailAddress });
  }, []);

  const usePassword = useCallback(() => {
    setAuthSession((s) => ({ ...s, next: 'password' }));
  }, []);

  const usePasswordless = useCallback(() => {
    setAuthSession((s) => ({ ...s, next: 'passwordless' }));
  }, []);

  useEffect(() => {
    const qs = new URLSearchParams(window.location.search);
    if (qs.has('e')) {
      const email = qs.get('e');
      setDefaultEmailAddress(email!);
    }
  }, []);

  const showForgotPassword = useCallback(() => {
    setAuthSession((a) => ({ ...a, next: 'forgot-password' }));
  }, []);

  const handleEmail = useCallback(async (formData: object): Promise<boolean> => {
    const { email = '' } = formData as { email: string };

    try {
      setIsProcessing(true);
      const response = await login.initiate(email);
      setAuthSession(response);
      setIsProcessing(false);
      return true;
    } catch (e: unknown) {
      if ((e as Error).message === 'Request failed with status code 404') {
        //don't get why I see in the network error message 'user not found' but the message from error is request failed with...
        setAuthSession((a) => ({
          email: '',
          errorMessage: "Couldn't find account. Please try again"
        }));
        setIsProcessing(false);
        return false;
      } else {
        setAuthSession((a) => ({
          email: '',
          errorMessage: (e as Error).message
        }));
        setIsProcessing(false);
        return false;
      }
    }
  }, []);

  const handlePasswordless = useCallback(
    async (formData: object): Promise<boolean> => {
      const { code = '' } = formData as { code: string };
      try {
        setIsProcessing(true);
        const response = await login.passwordless.check(authSession, code);
        setAuthSession(response);
        setIsProcessing(false);
        return true;
      } catch (e: unknown) {
        if ((e as Error).message === 'Incorrect username or password.') {
          setAuthSession({
            email: '',
            errorMessage: 'Please retry'
          });
        } else {
          setAuthSession((a) => ({
            ...a,
            errorMessage: 'Invalid code, please try again'
          }));
        }
        setIsProcessing(false);
        return false;
      }
    },
    [authSession]
  );

  const handlePassword = useCallback(
    async (formData: object): Promise<boolean> => {
      if (!authSession.email) return false;
      const { password } = formData as { password: string };

      try {
        setIsProcessing(true);
        const response = await login.password.check(authSession, password);
        setAuthSession(response);
        setIsProcessing(false);
        return true;
      } catch (e) {
        if (authSession.retry && authSession.retry >= 2) {
          setAuthSession({
            email: '',
            errorMessage: 'Please retry'
          });
        } else {
          setAuthSession((a) => ({
            ...a,
            retry: a.retry ? ++a.retry : 1,
            errorMessage: 'Invalid email or password'
          }));
        }
        setIsProcessing(false);
        return false;
      }
    },
    [authSession]
  );

  const handleForgotPassword = useCallback(
    async (formData: object): Promise<boolean> => {
      try {
        setIsProcessing(true);
        const code = (formData as unknown as { code: string }).code;
        const newPassword = (formData as unknown as { password: string }).password;
        await login.password.forgotChange(authSession, code, newPassword);
        await handleEmail({ email: authSession.email });
        setIsProcessing(false);
        return true;
      } catch (e: unknown) {
        if (authSession.retry && authSession.retry >= 2) {
          setAuthSession((a) => ({
            email: '',
            errorMessage: (e as Error).message + ' Please retry'
          }));
        } else {
          setAuthSession((a) => ({
            ...a,
            retry: a.retry ? ++a.retry : 1,
            errorMessage: (e as Error).message
          }));
        }

        setIsProcessing(false);
        return false;
      }
    },
    [authSession]
  );

  const handleForgotPasswordSendCode = useCallback(async () => {
    try {
      setIsProcessing(true);
      await login.password.forgot(authSession);
      setIsProcessing(false);
      return true;
    } catch (e: unknown) {
      setAuthSession((a) => ({
        email: '',
        errorMessage: (e as Error).message
      }));

      setIsProcessing(false);
      return false;
    }
  }, [authSession]);

  const handleSetupNewPassword = useCallback(
    async (formData: object) => {
      const { newPassword } = formData as { newPassword: string };
      try {
        const response = await login.password.setup(authSession, newPassword);
        setAuthSession(response);
        return true;
      } catch (e: unknown) {
        setAuthSession((a) => ({
          email: '',
          errorMessage: (e as Error).message
        }));
        return false;
      }
    },
    [authSession]
  );

  const handleSignUp = useCallback(
    async (formData: object) => {
      try {
        setIsProcessing(true);
        const response = await login.signUp.setup(
          (formData as { email: string }).email,
          (formData as { password: string }).password
        );
        setAuthSession(response);
        setIsProcessing(false);
        return true;
      } catch (e: unknown) {
        setAuthSession((a) => ({
          email: '',
          errorMessage: (e as Error).message
        }));
        setIsProcessing(false);
        return false;
      }
    },
    [authSession]
  );

  const handleConfirmSignUp = useCallback(
    async (data: object): Promise<boolean> => {
      //auto sign in after sign up - hope is a good idea
      Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
          const user = payload.data;
          setAuthSession(user);
        } else if (event === 'autoSignIn_failure') {
          setAuthSession((a) => ({
            email: ''
          }));
        }
      });

      try {
        setIsProcessing(true);
        const code = (data as { code: string }).code;
        await login.signUp.confirm(authSession, code);
        setIsProcessing(false);
        return true;
      } catch (e: unknown) {
        setAuthSession((a) => ({
          email: '',
          errorMessage: (e as Error).message
        }));
        setIsProcessing(false);
        return false;
      }
    },
    [authSession]
  );

  const handleResendCode = useCallback(async () => {
    try {
      setIsProcessing(true);
      await login.signUp.resendCode(authSession);
      setIsProcessing(false);
    } catch (e: unknown) {
      setAuthSession((a) => ({
        email: '',
        errorMessage: (e as Error).message
      }));
      setIsProcessing(false);
    }
  }, [authSession]);

  const props: IScreenProps = {
    authSession,
    defaultEmailAddress,
    showSignUp,
    showSignIn,
    showForgotPassword,
    usePassword,
    usePasswordless,
    handleConfirmSignUp,
    handleSignUp,
    handleResendCode,
    handleSetupNewPassword,
    handleForgotPasswordSendCode,
    handleForgotPassword,
    handlePassword,
    handlePasswordless,
    handleEmail,
    isProcessing
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <Card
        elevation={24}
        sx={{
          display: 'flex',
          p: 2,
          width: '400px',
          minHeight: '650px'
        }}
      >
        <Box
          sx={{
            p: 3,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              m: 2
            }}
          >
            <Logo />
          </Box>

          {!authSession.next && <Screens.Email {...props} />}

          {authSession?.next === 'idp-redirect' && <Screens.IdpRedirect {...props} />}

          {authSession?.next === 'passwordless' && <Screens.Passwordless {...props} />}

          {authSession?.next === 'password' && <Screens.Password {...props} />}

          {/**authSession?.next === 'mfa' && (
            <Screens.MFA {...props} />
          )*/}

          {authSession?.next === 'new-password' && <Screens.NewPassword {...props} />}

          {/**authSession?.next === 'new-mfa' && (
            <Screens.NewMFA  {...props}  />
          )*/}

          {authSession.next === 'forgot-password' && <Screens.ForgotPassword {...props} />}
          {authSession.next === 'signup' && <Screens.SignUp {...props} />}

          {authSession.next === 'signup-confirmation' && <Screens.SignUpConfirmation {...props} />}

          <Box>
            <ErrorMessage error={authSession.errorMessage} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="caption">
              Build with 💛 by{' '}
              <a
                href="https://iwn.studio/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                iwn studio
              </a>
            </Typography>
            <Box>
              <FormControl
                size="small"
                variant="standard"
                sx={{ m: 1, minWidth: 80 }}
                disabled={isProcessing}
              >
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={locale}
                  onChange={handleSetLocale}
                  label="Language"
                  autoWidth
                  sx={{ fontSize: '12px' }}
                >
                  {availableLocales.map((l) => (
                    <MenuItem key={l} dense value={l}>
                      {LANGUAGES[l]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};
