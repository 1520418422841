import React, { useCallback } from 'react';
import { useWorkspaceShow, useWorkspaceUpdate, useFileUpload } from '@koopajs/react';
import { Settings, ErrorMessage } from '@koopajs/mui';
import { Divider, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

export const SettingsGeneral: React.FC = () => {
  const { workspace, errorMessage } = useWorkspaceShow();
  const { updateWorkspace } = useWorkspaceUpdate();
  const { upload } = useFileUpload();

  const handleProfileChange = useCallback(
    async (values: { [k: string]: unknown }): Promise<boolean> => {
      return await updateWorkspace(values);
    },
    [updateWorkspace]
  );

  const handlePhotoChange = useCallback(
    async (photo: Blob): Promise<void> => {
      const icon = await upload(photo, 'cdn');
      await handleProfileChange({ icon });
    },
    [handleProfileChange]
  );

  if (!workspace) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Settings - Loopback</title>
      </Helmet>
      <Box>
        <ErrorMessage error={errorMessage} />

        <Box sx={{ py: 2 }}>
          <Settings.PhotoEditor
            label="Workspace Icon"
            description="An icon helps personalize your workspace."
            photoUrl={workspace.icon || ''}
            handleValueChange={handlePhotoChange}
          />
        </Box>

        <Divider />

        <Box sx={{ py: 2 }}>
          <Settings.FieldEditor
            uniqueDialogId="test2"
            //label="Name"
            value={workspace.name || 'Unknown'}
            //description="The name help personalize your product experience."
            fields={[{ key: 'name', label: 'Name' }]}
            defaultValues={workspace}
            handleValueChange={handleProfileChange}
          />
        </Box>

        <Divider />

        <Box sx={{ py: 2 }}>
          <Settings.ReadOnly label="Workspace ID" value={`w:${workspace.id}`} />
        </Box>
      </Box>
    </>
  );
};
