import React from 'react';
import { FinancesPortfolios } from './pages/Portfolios';
import { FinancesBudgets } from './pages/Budgets';
import { FinancesAssets } from './pages/Assets';
import { Layout } from '@koopajs/mui';

export const Finances: React.FC = () => (
  <Layout.MultiPageContainer
    title="Finances"
    homepagePath="/finances"
    pages={[
      {
        title: 'Portfolios',
        path: 'portfolios',
        component: FinancesPortfolios
      },
      {
        title: 'Assets',
        path: 'assets',
        component: FinancesAssets
      },
      {
        title: 'Budgets',
        path: 'budgets',
        component: FinancesBudgets
      }
      // {
      //   title: 'System Logs',
      //   path: 'system-logs',
      //   component: SettingsSystemLogs
      // }
    ]}
  />
);
