import React, { useCallback, useState } from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Box, Typography, Button, TextField, InputAdornment } from '@mui/material';
import { validatePassword } from '../utils';
import { PasswordValidation } from './PasswordValidation';
import { IScreenProps } from '../types';

export const NewPassword: React.FC<IScreenProps> = (props) => {
  const { showSignIn, handleSetupNewPassword, authSession } = props;

  const [errors, setErrors] = useState<string[]>([]);
  const [isPasswordEqual, setPasswordEqual] = useState(false);

  const { t } = useLocale();

  const watchFields = useCallback(
    (value: { password?: string; repeatedPassword?: string; code?: string }) => {
      const check = validatePassword(value.password);
      const isEqual = value.password === value.repeatedPassword;
      setErrors(check);
      setPasswordEqual(isEqual);
    },
    []
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4">{t('Authorization.NewPassword.title')}</Typography>
      <Typography>{t('Authorization.NewPassword.description')}</Typography>

      <FormController
        onSubmit={handleSetupNewPassword}
        watch={watchFields}
        defaultValues={{ password: '', repeatedPassword: '' }}
      >
        <TextField
          label={t('Authorization.Email.FormEmail.label')}
          defaultValue={authSession?.email}
          fullWidth
          disabled
          sx={{ mt: 2, mb: 1 }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button size="small" onClick={showSignIn}>
                  {t('Authorization.labelEdit')}
                </Button>
              </InputAdornment>
            )
          }}
        />
        <PasswordValidation errors={errors} passwordsMatch={isPasswordEqual} />
        <Form.ButtonSubmit sx={{ mt: 1, p: 1, width: '100% ' }} />
      </FormController>
    </Box>
  );
};
