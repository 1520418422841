import React, { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import { useParamsKey } from '@koopajs/react';
import { Api } from '@koopajs/app';

export const Invitation: React.FC = () => {
  const token = useParamsKey();
  const [workspaceName, setWorkspaceName] = useState<string>();

  useEffect(() => {
    const loadInvitation = async (): Promise<void> => {
      const { data } = await Api.client.get(`/invitations/${token}`);
      setWorkspaceName(data.invitation.workspaceName);
    };

    loadInvitation().catch(console.error);
  }, [token]);

  const handleAccept = useCallback(async () => {
    await Api.client.post(`/invitations/${token}/accepts`);
    Api.setActiveWorkspaceId(token.split('.')[0]);
    location.replace('/');
  }, []);

  const handleDecline = useCallback(async () => {
    const { data } = await Api.client.post(`/invitations/${token}/rejects`);
    console.log(data);
  }, []);

  if (!workspaceName) {
    return <div>Loading invitation details...</div>;
  }

  return (
    <div>
      <h1>You are invited!</h1>
      <p>You are invited to {workspaceName}.</p>

      <p>
        <Button onClick={handleAccept}>Accept</Button> - or -<Button onClick={handleDecline}>Decline</Button>
      </p>
    </div>
  );
};
