import React, { useState, useCallback, useEffect } from 'react';
import { FormController, useLocale } from '@koopajs/react';
import { Form } from '@koopajs/mui';
import { Typography, Box, Button, TextField, InputAdornment } from '@mui/material';
import { validatePassword, PASSWORD_VALIDATION_FUNCTIONS } from '../utils';
import { PasswordValidation } from './PasswordValidation';
import { IScreenProps } from '../types';

export const ForgotPassword: React.FC<IScreenProps> = ({
  handleForgotPasswordSendCode,
  showSignIn,
  authSession,
  handleForgotPassword
}) => {
  const [errors, setErrors] = useState<string[]>(Object.keys(PASSWORD_VALIDATION_FUNCTIONS));
  const [isPasswordEqual, setPasswordEqual] = useState(false);

  const { t } = useLocale();

  useEffect(() => {
    const sendCode = async (): Promise<void> => {
      await handleForgotPasswordSendCode();
    };
    sendCode().catch((e) => console.log(e));
  }, [authSession]);

  const watchFields = useCallback(
    (value: { password?: string; repeatedPassword?: string; code?: string }) => {
      const check = validatePassword(value.password);
      const isEqual = value.password === value.repeatedPassword;
      setErrors(check);
      setPasswordEqual(isEqual);
    },
    []
  );

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography>{t('Authorization.ForgotPassword.checkEmail')}</Typography>
      <Typography>{t('Authorization.ForgotPassword.weSentCode')}</Typography>

      <FormController
        onSubmit={handleForgotPassword}
        watch={watchFields}
        defaultValues={{ password: '', repeatedPassword: '', code: '' }}
      >
        <TextField
          label={t('Authorization.Email.FormEmail.label')}
          defaultValue={authSession?.email}
          fullWidth
          disabled
          sx={{ mt: 2, mb: 1 }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Button size="small" onClick={showSignIn}>
                  {t('Authorization.labelEdit')}
                </Button>
              </InputAdornment>
            )
          }}
        />

        <PasswordValidation errors={errors} passwordsMatch={isPasswordEqual} />

        <Form.TextField i18n={{ keyPrefix: 'Authorization.ForgotPassword.FormTextField' }} name="code" />
        <Form.ButtonSubmit sx={{ mt: 1, p: 1, width: '100% ' }} />
      </FormController>
    </Box>
  );
};
