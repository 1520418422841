import React, { useEffect } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { IScreenProps } from '../types';
import { useLocale } from '@koopajs/react';
import { Trans } from 'react-i18next';

//will remove i18nTransComponents once we move to koopa
const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export const IdpRedirect: React.FC<IScreenProps> = ({ authSession }) => {
  const { t } = useLocale();
  useEffect(() => {
    if (authSession?.redirect) {
      Auth.federatedSignIn({
        customProvider: authSession.redirect
      }).finally(() => null);
    }
  }, [authSession?.redirect]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t('Authorization.title')}</Typography>
      <Typography>{t('Authorization.subtitle', { appName: 'Charlie' })}</Typography>

      <Box sx={{ mt: 3 }}>
        <UpdateIcon />
      </Box>
      <Typography variant="body2">
        <Trans i18nKey="Authorization.IdpRedirect.redirect-html" t={t} components={i18nTransComponents} />
      </Typography>
    </Box>
  );
};
