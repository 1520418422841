import React, { useCallback, useState } from 'react';
import { ContentTOC } from './Steps/ContentTOC';
import { ContentName } from './Steps/ContentName';
import { ContentWorkspace } from './Steps/ContentWorkspace';
import { ContentSuccess } from './Steps/ContentSuccess';
import { Layout } from '@koopajs/mui';
import { Stepper, Step, StepContent, StepLabel, Typography, Paper } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';

export const Onboarding: React.FC = () => {
  const match = useRouteMatch('/invitation/:token');

  const [step, setStep] = useState(0);
  const moveNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const movePrevious = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  return (
    <Layout.PageContainer fluidContainer>
      <Paper className={''} elevation={1} style={{ padding: 20 }}>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          <Typography variant="h3">Welcome to Charlie.</Typography>
          <Typography variant="subtitle1">Please read and accept the terms and conditions below.</Typography>
        </div>

        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>Terms and Conditions</StepLabel>
            <StepContent>
              <ContentTOC moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Name</StepLabel>
            <StepContent>
              <ContentName moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          {!match && (
            <Step>
              <StepLabel>Your Workspace</StepLabel>
              <StepContent>
                <ContentWorkspace moveNext={moveNext} movePrevious={movePrevious} />
              </StepContent>
            </Step>
          )}

          <Step>
            <StepLabel>Ready!</StepLabel>
            <StepContent>
              <ContentSuccess moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>
        </Stepper>
      </Paper>
    </Layout.PageContainer>
  );
};
